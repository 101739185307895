import React from 'react'
import PropTypes from 'prop-types'

const Col = (props) => {
  const { className, sm, md, lg, xl, xxl, children } = props

  return (
    <div
      className={`${className === '' ? '' : (className + ' ')}col\
${sm === 0 ? '' : ` col-sm-${sm}`}\
${md === 0 ? '' : ` col-md-${md}`}\
${lg === 0 ? '' : ` col-lg-${lg}`}\
${xl === 0 ? '' : ` col-xl-${xl}`}\
${xxl === 0 ? '' : ` col-xxl-${xxl}`}`
      }
    >
      {children}
    </div>
  )
}

Col.propTypes = {
  className: PropTypes.string,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Col.defaultProps = {
  className: '',
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
  xxl: 0
}

export default Col
