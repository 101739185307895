/* eslint-disable react/prop-types */
import React, { createContext, useContext } from 'react'

/* ---------------------------------------------------- */
/* -------------- Authentication Context -------------- */
/* ---------------------------------------------------- */
const authContext = createContext()

const useProvideAuth = () => {
  const token = localStorage.getItem('token')
  return {
    token: token || null
  }
}

export const useAuth = () => {
  return useContext(authContext)
}

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()
  return (
     <authContext.Provider value={auth}>
       {children}
     </authContext.Provider>
  )
}
