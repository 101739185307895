import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import axios from 'axios'
import { useLocation, useParams } from 'react-router-dom'
import Navbar from './Navbar'
import LeftSidebar from './LeftSidebar'
// import RightSidebar from './RightSidebar'
import { useScript } from 'common/hook'
import imgUser from 'components/ui/styles/images/user.png'
import imgLogo from 'components/ui/styles/images/logo.png'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/layout.scss'

const Layout = (props) => {
  const { children } = props

  useScript('/vendor/theme/theme.js')

  const location = useLocation()
  const { id } = useParams()

  const [title, setTitle] = useState('Dashboard')
  const [collapse, setCollapse] = useState(false)
  // const [username, setUsername] = useState('')

  // useEffect(() => {
  //   if (localStorage.getItem('username')) {
  //     setUsername(localStorage.getItem('username'))
  //   }
  // }, [])

  useEffect(() => {
    if (location) {
      if (location.pathname === '/') {
        setTitle('Dashboard')
      } else if (location.pathname === '/tenant') {
        setTitle('Tenant List')
      } else if (location.pathname === '/tenant/add') {
        setTitle('Add Tenant')
      } else if (location.pathname === `/tenant/${id}`) {
        setTitle('Edit Tenant')
      } else if (location.pathname === '/config') {
        setTitle('Configs')
      } else if (location.pathname === '/config/add') {
        setTitle('Add Config')
      } else if (location.pathname === `/config/${id}`) {
        setTitle('Edit Config')
      }
    }
  }, [location])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleClickLogout = async () => {
    localStorage.removeItem('token')
    window.location.reload()
    // try {
    //   if (localStorage.getItem('token')) {
    //     const sessionId = localStorage.getItem('token')
    //     // eslint-disable-next-line no-unused-vars
    //     const resultLogout = await axios({
    //       headers: {
    //         Authorization: `Bearer ${sessionId}`
    //       },
    //       method: 'GET',
    //       url: `${process.env.REACT_APP_API_URL}logout`
    //     })

    //     localStorage.removeItem('token')
    //     window.location.reload()
    //   }
    // } catch (err) {
    //   if (err.response) {
    //     const data = err.response.data

    //     if (typeof data === 'string') {
    //       alert('Session expired, please login again.')
    //       localStorage.removeItem('token')
    //       window.location.reload()
    //     }
    //   }
    //   console.log(err)
    // }
  }

  const handleCollapse = (value) => {
    setCollapse(value)
  }

  return (
    <>
      <Navbar
        logo={imgLogo}
        onLogout={handleClickLogout}
        onCollapse={handleCollapse}
      />

      <div className="main_content" id="main-content">
        <LeftSidebar avatar={imgUser} collapse={collapse} />
        {/* <RightSidebar /> */}

        <div className="page">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#">{title}</a>
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fa fa-align-justify"></i>
              </button> */}
          </nav>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-md-12">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={4000}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Layout.defaultProps = {
  className: ''
}

export default Layout
