import React from 'react'
import PropTypes from 'prop-types'
import CardMui from '@mui/material/Card'
// import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

const Card = (props) => {
  const { className, children } = props

  return (
    <div className={`adm-card${className === '' ? '' : (' ' + className)}`}>
      <CardMui>
        {/* <CardHeader title={title}></CardHeader> */}
        <CardContent>
          {children}
        </CardContent>
      </CardMui>
    </div>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  // title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Card.defaultProps = {
  className: ''
  // title: ''
}

export default Card
