import React from 'react'
import { useSelector } from 'react-redux'

const Error = (props) => {
  const errorHtml = useSelector((state) => state.root.errorHtml)

  return (
    <div className="error">
      {errorHtml &&
        <div dangerouslySetInnerHTML={{
          __html: errorHtml
        }}></div>
      }
    </div>
  )
}

export default Error
