import React from 'react'
import Layout from 'features/layout/components/Layout'
import TenantForm from '../components/TenantForm'

const TenantFormPage = (props) => {
  return (
    <Layout>
      <TenantForm />
    </Layout>
  )
}

export default TenantFormPage
