/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
// import './styles/daterangepicker.scss'

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="form-group">
    <div className="input-group">
        <input type="text" className="form-control" onClick={onClick} onChange={() => {}} value={value} ref={ref} />
        <div className="input-group-append"><span className="input-group-text"><i className="fa fa-calendar"></i></span></div>
    </div>
  </div>
))

const DateRangePicker = (props) => {
  const { dateFormat, dateRange, onChange } = props

  const [startDate, endDate] = dateRange

  return (
    <ReactDatePicker
      selectsRange
      // isClearable
      dateFormat={dateFormat}
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      customInput={<CustomInput />}
      maxDate={new Date()}
    />
  )
}

DateRangePicker.propTypes = {
  dateFormat: PropTypes.string,
  dateRange: PropTypes.array,
  onChange: PropTypes.func
}

DateRangePicker.defaultProps = {
  dateFormat: 'dd/MM/yyyy',
  dateRange: [null, null],
  onChange: () => {}
}

export default DateRangePicker
