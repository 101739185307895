import React from 'react'
import { Controller } from 'react-hook-form'
import TextFieldMui from '@mui/material/TextField'
import AutocompleteMui from '@mui/material/Autocomplete'
import PropTypes from 'prop-types'

const Autocomplete = (props) => {
  const { className, label, name, fullWidth, size, required, disabled, options, control, error, onHandleChange } = props

  return (
    <div className={`${className === '' ? '' : (' ' + className)}`}>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => {
          return (
            <AutocompleteMui
              // disablePortal
              fullWidth={fullWidth}
              disabled={disabled}
              size={size}
              options={options}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              disableClearable
              onChange={(e, newValue) => {
                onChange(newValue)

                if (onHandleChange && typeof onHandleChange === 'function') {
                  onHandleChange(newValue)
                }
              }}
              value={value || null}
              renderInput={(params) =>
                <TextFieldMui
                  {...params}
                  label={label}
                  required={required}
                  error={!!error}
                  helperText={error
                    ? error.type === 'required'
                      ? 'This field is required'
                      : error.message
                    : null
                  }
                />
              }
            />

          )
        }}
      />
    </div>
  )
}

Autocomplete.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  control: PropTypes.any,
  error: PropTypes.any,
  onHandleChange: PropTypes.func
}

Autocomplete.defaultProps = {
  className: '',
  label: '',
  name: '',
  fullWidth: false,
  disabled: false,
  size: 'small',
  required: false,
  options: []
}

export default Autocomplete
