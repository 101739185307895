import React from 'react'
import PropTypes from 'prop-types'

const Form = (props) => {
  const { className, children } = props

  return (
    <form className={`needs-validation${className === '' ? '' : (' ' + className)}`} noValidate>
      {children}
    </form>
  )
}

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Form.defaultProps = {
  className: ''
}

export default Form
