import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

const InputGroup = (props) => {
  const { label, type, name, required, disabled, control, error, onKeyDown } = props

  return (
    <>
      <label htmlFor={name} className="form-label mb-1">{label} {required && <span className="text-danger">*</span>}</label>
      <Controller
        name={name}
        control={control}
        defaultValue={''}
        rules={{ required: required }}
        render={({ field }) => {
          if (type === 'textarea') {
            return (
              <textarea rows={3} disabled={disabled} className={`form-control ${error ? 'is-invalid' : ''}`} id={name} {...field} onKeyDown={onKeyDown} />
            )
          } else {
            return (
              <input type={type} disabled={disabled} className={`form-control ${error ? 'is-invalid' : ''}`} id={name} {...field} onKeyDown={onKeyDown} />
            )
          }
        }}
      />
      {error
        ? error.type === 'required'
          ? <div className="invalid-feedback">
              {label} required
            </div>
          : <div className="invalid-feedback">
              {error.message}
            </div>
        : <div className="invalid-feedback"></div>
      }
    </>
  )
}

InputGroup.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  control: PropTypes.any,
  error: PropTypes.any,
  onKeyDown: PropTypes.func
}

InputGroup.defaultProps = {
  label: '',
  type: 'text',
  name: '',
  required: false,
  disabled: false
}

export default InputGroup
