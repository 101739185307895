import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import './styles/autocomplete.scss'

const Autocomplete = (props) => {
  const { className, data, value, isSearchable, onChange } = props

  return (
    <div className={`analytic-autocomplete ${className === '' ? '' : (' ' + className)}`}>
      <Select
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        options={data}
        value={value}
        onChange={onChange}
        isSearchable={isSearchable}
      />
    </div>
  )
}

Autocomplete.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.object,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func
}

Autocomplete.defaultProps = {
  className: '',
  data: [],
  value: {},
  isSearchable: true,
  onChange: () => {}
}

export default Autocomplete
