import React from 'react'
import PropTypes from 'prop-types'
import 'antd/lib/table/style/index.css'
import 'antd/lib/spin/style/index.css'
import { Table } from 'antd'
import './styles/table-antd.scss'

const TableAntd = (props) => {
  const { className, columns, data, scroll, pagination, summary, loading } = props

  return (
    <div className="table-antd">
      <Table
        className={className}
        columns={columns}
        dataSource={data}
        scroll={scroll}
        bordered
        pagination={pagination}
        summary={summary}
        loading={loading}
      />
    </div>
  )
}

TableAntd.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  scroll: PropTypes.object,
  loading: PropTypes.bool,
  pagination: PropTypes.bool,
  summary: PropTypes.any
}

TableAntd.defaultProps = {
  className: '',
  columns: [],
  data: [],
  scroll: null,
  pagination: false,
  loading: false
}

export default TableAntd
