import React from 'react'
import Layout from 'features/layout/components/Layout'
import Config from '../components/Config'

const ConfigPage = (props) => {
  return (
    <Layout>
      <Config />
    </Layout>
  )
}

export default ConfigPage
