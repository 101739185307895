/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// import './styles/datepicker.scss'

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="form-group">
    <div className="input-group">
        <input type="text" className="form-control" onClick={onClick} onChange={() => {}} value={value} ref={ref} />
        <div className="input-group-append"><span className="input-group-text"><i className="fa fa-calendar"></i></span></div>
    </div>
  </div>
))

const DatePicker = (props) => {
  const { dateFormat, selectedDate, onChange } = props

  return (
    <ReactDatePicker
      dateFormat={dateFormat}
      selected={selectedDate}
      onChange={onChange}
      customInput={<CustomInput />}
      maxDate={new Date()}
    />
  )
}

DatePicker.propTypes = {
  dateFormat: PropTypes.string,
  selectedDate: PropTypes.any,
  onChange: PropTypes.func
}

DatePicker.defaultProps = {
  dateFormat: 'dd/MM/yyyy',
  onChange: () => {}
}

export default DatePicker
