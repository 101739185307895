import React from 'react'
import PropTypes from 'prop-types'
import 'antd/lib/switch/style/index.css'

const CheckboxGroup = (props) => {
  const { className, label } = props

  return (
    <div className={`form-check${className === '' ? '' : (' ' + className)}`}>
      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        {label}
      </label>
    </div>
  )
}

CheckboxGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string
}

CheckboxGroup.defaultProps = {
  className: '',
  label: ''
}

export default CheckboxGroup
