import React from 'react'
import PropTypes from 'prop-types'
import 'antd/lib/table/style/index.css'
import 'antd/lib/checkbox/style/index.css'
import { Table as TableAntd } from 'antd'
import './styles/table.scss'

// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//     // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
//   }
// }

const Table = (props) => {
  const { columns, data, scroll, loading, pagination, isSummary } = props

  return (
    <div className="table-antd">
      <TableAntd
        style={{
          whiteSpace: 'pre'
        }}
        className={isSummary ? 'table-summary' : ''}
        columns={columns}
        dataSource={data}
        scroll={scroll}
        loading={loading}
        bordered
        pagination={pagination}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection
        // }}
      />
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  scroll: PropTypes.object,
  loading: PropTypes.bool,
  pagination: PropTypes.bool,
  isSummary: PropTypes.bool
}

Table.defaultProps = {
  columns: [],
  data: [],
  scroll: {
    x: 1000
  },
  // scroll: null,
  loading: false,
  pagination: false,
  isSummary: false
}

export default Table
