import React from 'react'
import PropTypes from 'prop-types'

const Row = (props) => {
  const { className, children } = props

  return (
    <div className={`row${className === '' ? '' : (' ' + className)}`}>
      {children}
    </div>
  )
}

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Row.defaultProps = {
  className: ''
}

export default Row
