import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData } from 'common/utils'
import { Row, Col, Table } from 'components/ui'
import Pagination from '@mui/material/Pagination'
// import AlertDialog from 'components/ui/AlertDialog'
import Tooltip from '@mui/material/Tooltip'
import Button from 'components/form/Button'
import IconButton from '@mui/material/IconButton'
// import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import LoginIcon from '@mui/icons-material/Login'
import SettingsIcon from '@mui/icons-material/Settings'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Select } from 'antd'
// import 'antd/lib/pagination/style/index.css'
import 'antd/lib/select/style/index.css'
// import { toast } from 'react-toastify'
import '../styles/core-list.scss'

const { Option } = Select

const CoreList = (props) => {
  const { code, endpoint, url, actionConfig, params, urlAdd, columns, limit } = props
  const history = useHistory()

  // const queryClient = useQueryClient()

  // const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [total, setTotal] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [limitData, setLimitData] = useState(10)
  const [current, setCurrent] = useState(1)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])
  // const [idDelete, setIdDelete] = useState(null)
  const [columnData, setColumnData] = useState([])
  const [anchorList, setAnchorList] = useState([])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const pageList = [
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' }
  ]

  const { data: dataList, isLoading, isFetching } = useQuery([`get${code}List`, limitData, offset], async () => {
    return await getData('GET', `${endpoint}/?limit=${limitData}&offset=${offset}${params}`)
  })

  // const mutationDelete = useMutation(async (id) => {
  //   if (id) {
  //     return await getData('DELETE', `${endpoint}/${id}/`)
  //   }
  // }, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(`get${code}List`)
  //   }
  // })

  useEffect(() => {
    if (limit) {
      setLimitData(limit)
    }
  }, [limit])

  useEffect(() => {
    if (Array.isArray(columns)) {
      const token = localStorage.getItem('token')
      const refreshToken = localStorage.getItem('adm_token')
      const profileId = localStorage.getItem('profileid')
      const showUserMenu = localStorage.getItem('show_user_menu')
      const username = localStorage.getItem('username')

      const columnTemp = [
        {
          title: 'Action',
          key: 'operation',
          fixed: 'left',
          align: 'center',
          width: '15%',
          // eslint-disable-next-line react/display-name
          render: (text, record, index) => {
            return (
              <div>
                {/* <Tooltip title="Delete">
                  <IconButton color="error" onClick={e => handleClickShowModalDelete(e, record)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip> */}
                <Tooltip title="Edit">
                  <Link to={`/${url}/${record.id}`}>
                    <IconButton color="success">
                      <EditIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                {
                  actionConfig && (
                    <Tooltip title="Settings" onClick={e => handleClickSetting(e, record)}>
                      {/* <Link to={`tenant/${record.id}/configs`}> */}
                      <IconButton color="secondary">
                        <SettingsIcon />
                      </IconButton>
                      {/* </Link> */}
                    </Tooltip>
                  )
                }
                {
                  actionConfig && (
                    <Tooltip title="Login as">
                      <a
                        href={`https://${record.alias}.${process.env.REACT_APP_BASE_DOMAIN}/login/?token=${token}&refresh_token=${refreshToken}&username=${username}&tenant=${record.id}&profileid=${profileId}&show_user_menu=${showUserMenu}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconButton color="primary">
                          <LoginIcon />
                        </IconButton>
                      </a>
                    </Tooltip>
                  )
                }
              </div>
            )
          }
        }
      ]
      const columnConcat = columnTemp.concat(columns)
      setColumnData(columnConcat)
    }
  }, [columns])

  useEffect(() => {
    if (dataList && limitData) {
      let resultData = []
      const dataListData = dataList.data

      if (dataListData) {
        if (Array.isArray(dataListData)) {
          resultData = dataListData
          setTotal(dataListData.length)
          setTotalPage(Math.ceil(dataListData.length / limitData))
        } else {
          resultData = dataListData.results
          setTotal(dataListData.total)
          setTotalPage(Math.ceil(dataListData.total / limitData))
        }
      }

      if (Array.isArray(resultData)) {
        const dataListParse = resultData.map(item => {
          return {
            key: item.id,
            ...item
          }
        })

        if (url === 'user') {
          const dataTemp = dataListParse.filter(item => {
            return item.username !== 'admin'
          })
          setData(dataTemp)
        } else {
          setData(dataListParse)
        }
      }
    }
  }, [dataList, limitData])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const handleChangePage = (event, value) => {
    setCurrent(value)
    setOffset((value - 1) * limitData)
  }

  const handleChangeLimit = (value) => {
    setLimitData(value)
    setCurrent(0)
    setOffset(0)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleViewConfigs = (recordId) => {
    history.push(`tenant/${recordId}/configs`)
  }

  const handleViewServers = (recordId) => {
    history.push(`tenant/${recordId}/servers`)
  }

  const handleClickSetting = (event, record) => {
    setAnchorList([
      [
        {
          label: 'Configs',
          onClick: () => {
            handleCloseMenu()
            handleViewConfigs(record.id)
          }
        },
        {
          label: 'Servers',
          onClick: () => {
            handleCloseMenu()
            handleViewServers(record.id)
          }
        }
      ]
    ])

    setAnchorEl(event.currentTarget)
  }
  // const handleClickShowModalDelete = (e, record) => {
  //   setIdDelete(record.id)
  //   setIsOpenDelete(true)
  // }

  // const handleCloseDelete = () => {
  //   setIsOpenDelete(false)
  // }

  // const handleClickDelete = async () => {
  //   try {
  //     await mutationDelete.mutateAsync(idDelete)
  //     setIsOpenDelete(false)

  //     toast.success(`${code} deleted successfully`)
  //   } catch (err) {
  //     setIsOpenDelete(false)
  //     toast.error('Something went wrong!')
  //     console.log(err)
  //   }
  // }

  return (
    <div className="user">
      <Row>
        <Col md={12}>
          <Link to={urlAdd ? `${urlAdd}/add` : `/${url}/add`}>
            <Button className="float-right" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="pb-1">
        <Col md={12}>
          <div className="total-text">Total: {total}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            columns={columnData}
            data={data}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            width: 320
          }
        }}
      >
        {anchorList && anchorList.map((item, index) => {
          return (
            <Row key={index}>
              {Array.isArray(item) && item.map((itemChild, indexChild) => {
                return (
                  <Col md={6} key={indexChild}>
                    <MenuItem onClick={itemChild.onClick}>{itemChild.label}</MenuItem>
                  </Col>
                )
              })}
            </Row>
          )
        })}
      </Menu>

      <Row className="pt-4">
        <Col md={12}>
          <Select className="float-right ml-4" value={limitData} onChange={handleChangeLimit} style={{ width: 110 }}>
            {pageList && pageList.map((item, index) => {
              return (
                <Option key={index} value={item.value}>{item.label}</Option>
              )
            })}
          </Select>

          <Pagination
            className="float-right"
            count={totalPage}
            page={current}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            color="standard"
          />
        </Col>
      </Row>

      {/* <AlertDialog
        isOpen={isOpenDelete}
        title="Notification"
        contentText="This action cannot be undone. Are you sure you want to delete this item?"
        onClose={handleCloseDelete}
        onSave={handleClickDelete}
      /> */}
    </div>
  )
}

CoreList.propTypes = {
  code: PropTypes.string,
  endpoint: PropTypes.string,
  url: PropTypes.string,
  columns: PropTypes.array,
  actionConfig: PropTypes.bool,
  params: PropTypes.string,
  urlAdd: PropTypes.string,
  limit: PropTypes.number
}

CoreList.defaultProps = {
  code: '',
  endpoint: '',
  url: '',
  actionConfig: false,
  params: '',
  urlAdd: '',
  columns: [],
  limit: 10
}

export default CoreList
