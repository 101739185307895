import React from 'react'
import Layout from 'features/layout/components/Layout'
import ConfigForm from '../components/ConfigForm'

const ConfigFormPage = (props) => {
  return (
    <Layout>
      <ConfigForm />
    </Layout>
  )
}

export default ConfigFormPage
