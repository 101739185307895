import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { getData } from 'common/utils'
import Switch from '@mui/material/Switch'
import CoreList from 'features/core/components/CoreList'

const Tenant = (props) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(async ({ id, dataForm }) => {
    if (id && dataForm) {
      return await getData('PUT', `tenants/${id}/active/`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getTenantList')
    }
  })

  const handleChangeStatus = async (e, id) => {
    try {
      await mutation.mutateAsync({
        id: id,
        dataForm: {
          is_active: e.target.checked
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ellipsis: true
    },
    {
      title: 'Alias',
      dataIndex: 'alias',
      key: 'alias',
      width: '10%',
      ellipsis: true
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      width: '15%',
      ellipsis: true
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '15%',
      ellipsis: true
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '15%',
      ellipsis: true
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <Switch checked={record.is_active} onChange={e => handleChangeStatus(e, record.id)} />
      },
      sorter: (a, b) => {
        return b.is_active - a.is_active
      }
    }
  ]

  return (
    <div className="tenant">
      <CoreList
        code="Tenant"
        endpoint="tenants"
        url="tenant"
        actionConfig={true}
        columns={columns}
      />
    </div>
  )
}

export default Tenant
