import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { getData, formatFormData } from 'common/utils'
import { Row, Col } from 'components/ui'
import TextField from 'components/form/TextField'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Autocomplete from 'components/form/Autocomplete'
import { toast } from 'react-toastify'
import { Tabs } from 'antd'
import 'antd/lib/tabs/style/index.css'

const { TabPane } = Tabs

const StorageType = [
  { value: 0, label: 'S3 Cloud' },
  { value: 1, label: 'FTP Cloud' }
]

const TenantForm = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const { handleSubmit, control, setValue, reset, formState: { errors }, setError } = useForm()

  // UseStates
  const [isConfigCloudTenant, setIsConfigCloudTenant] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('isConfigTenantCloud')) {
      setIsConfigCloudTenant(localStorage.getItem('isConfigTenantCloud'))
    }
  }, [])

  const { data: dataInfo } = useQuery(['getInfoTenant', id], async () => {
    if (id) {
      return await getData('GET', `tenants/${id}/`)
    }
  })

  const { data: dataConfigInfo } = useQuery(['getDataConfigTenantInfo', id, isConfigCloudTenant], async () => {
    if (id && isConfigCloudTenant) {
      return await getData('GET', `tenants/${id}/tenant_config`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `tenants/${id}/`, dataForm)
      } else {
        return await getData('POST', 'tenants/', dataForm)
      }
    }
  })

  const mutationUpdateConfig = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id && isConfigCloudTenant) {
        return await getData('PUT', `tenants/${id}/update_tenant_config/`, dataForm)
      }
    }
  })

  /* ----------------------------------------------- */
  /* ----------------- UseEffect ------------------- */
  /* ----------------------------------------------- */
  useEffect(() => {
    if (dataConfigInfo) {
      const dataInfoResult = dataConfigInfo?.data || {}
      // S3
      setValue('s3Host', dataInfoResult?.s3_host || '')
      setValue('s3AccessKey', dataInfoResult?.s3_access_key || '')
      setValue('s3SecretKey', dataInfoResult?.s3_secret_key || '')
      setValue('s3Bucket', dataInfoResult?.s3_bucket || '')
      setValue('s3Prefix', dataInfoResult?.s3_prefix || '')
      // FTP
      setValue('FTPHost', dataInfoResult?.ftp_host || '')
      setValue('FTPUser', dataInfoResult?.ftp_user || '')
      setValue('FTPPassword', dataInfoResult?.ftp_password || '')
      setValue('FTPPrefix', dataInfoResult?.ftp_prefix || '')
      // Mail
      setValue('mailHost', dataInfoResult?.mail_host || '')
      setValue('mailPort', dataInfoResult?.mail_port || '')
      setValue('mailUsername', dataInfoResult?.mail_username || '')
      setValue('mailPassword', dataInfoResult?.mail_password || '')
      setValue('mailSenderAddr', dataInfoResult?.mail_senderaddr || '')
      setValue('confirmAccountHost', dataInfoResult?.confirm_account_host || '')
      // More Info
      setValue('transcodeOutputPath', dataInfoResult?.transcode_output_path || '')
      setValue('appleClientIdIOS', dataInfoResult?.apple_client_id_ios || '')
      setValue('appleClientIdWeb', dataInfoResult?.apple_client_id_web || '')
      const storageFind = StorageType.find(item => item.value === dataInfoResult?.storage_type || 0)
      setValue('storageType', storageFind)
    }
  }, [dataConfigInfo])

  useEffect(() => {
    if (dataInfo) {
      const dataInfoResult = dataInfo.data
      reset(dataInfoResult)
    }
  }, [dataInfo])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        await mutation.mutateAsync({
          name: formatFormData(data.name),
          website: formatFormData(data.website),
          description: formatFormData(data.description),
          address: formatFormData(data.address)
        })
        if (isConfigCloudTenant) {
          await mutationUpdateConfig.mutateAsync({
            // S3
            s3_host: formatFormData(data.s3Host),
            s3_access_key: formatFormData(data.s3AccessKey),
            s3_secret_key: formatFormData(data.s3SecretKey),
            s3_bucket: formatFormData(data.s3Bucket),
            s3_prefix: formatFormData(data.s3Prefix),
            // FTP
            ftp_host: formatFormData(data.FTPHost),
            ftp_user: formatFormData(data.FTPUser),
            ftp_password: formatFormData(data.FTPPassword),
            ftp_prefix: formatFormData(data.FTPPrefix),
            // Mail
            mail_host: formatFormData(data?.mailHost || null),
            mail_port: formatFormData(data?.mailPort || null),
            mail_username: formatFormData(data?.mailUsername || null),
            mail_password: formatFormData(data?.mailPassword || null),
            mail_senderaddr: formatFormData(data?.mailSenderAddr || null),
            // Mail Template
            confirm_account_host: formatFormData(data?.confirmAccountHost || null),
            storage_type: formatFormData(data.storageType.value),
            // More Config
            transcode_output_path: formatFormData(data.transcodeOutputPath),
            apple_client_id_ios: formatFormData(data?.appleClientIdIOS || null),
            apple_client_id_web: formatFormData(data?.appleClientIdWeb || null)
          })
        }
      } else {
        await mutation.mutateAsync({
          name: formatFormData(data.name),
          alias: formatFormData(data.alias),
          website: formatFormData(data.website),
          description: formatFormData(data.description),
          address: formatFormData(data.address)
        })
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="tenant-form">
      <Card>
        <CardContent>
          <Tabs defaultActiveKey="1">
            <TabPane tab="BASIC" key="1">
              <Row>
                <Col md={4} className="offset-md-4">
                  <TextField label="Name" name="name" required control={control} error={errors.name} />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col md={4} className="offset-md-4">
                  <TextField label="Alias" name="alias" required={!id} disabled={!!id} control={control} error={errors.alias} />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col md={4} className="offset-md-4">
                  <TextField label="Website" name="website" control={control} error={errors.website} />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col md={4} className="offset-md-4">
                  <TextField type="textarea" label="Address" name="address" control={control} error={errors.address} />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col md={4} className="offset-md-4">
                  <TextField type="textarea" label="Description" name="description" control={control} error={errors.description} />
                </Col>
              </Row>
            </TabPane>
            {
              isConfigCloudTenant && id && (
                <>
                <TabPane tab="CONFIG CLOUD S3" key="2">
                  <Row>
                    <Col md={4} className="offset-md-4">
                      <TextField label="S3 Access Key" name="s3AccessKey" control={control} error={errors.s3AccessKey} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="S3 Bucket" name="s3Bucket" control={control} error={errors.s3Bucket} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="S3 Host" name="s3Host" control={control} error={errors.s3Host} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="S3 Prefix" name="s3Prefix" control={control} error={errors.s3Prefix} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="S3 Secret Key" name="s3SecretKey" control={control} error={errors.s3SecretKey} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="CONFIG CLOUD FTP" key="3">
                  <Row>
                    <Col md={4} className="offset-md-4">
                      <TextField label="FTP Host" name="FTPHost" control={control} error={errors.FTPHost} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="FTP Password" name="FTPPassword" control={control} error={errors.FTPPassword} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="FTP Prefix" name="FTPPrefix" control={control} error={errors.FTPPrefix} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="FTP User" name="FTPUser" control={control} error={errors.FTPUser} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="MORE INFO" key="4">
                  <Row>
                    <Col md={4} className="offset-md-4">
                      <TextField label="Mail Host" name="mailHost" control={control} error={errors.mailHost} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="Mail Port" name="mailPort" control={control} error={errors.mailPort} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="Mail Secure" name="mailSecure" control={control} error={errors.mailSecure} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="Mail UserName" name="mailUsername" control={control} error={errors.mailUsername} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="Mail Password"
                      name="mailPassword" type="password" control={control} error={errors.mailPassword} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="Mail SenderAddr" name="mailSenderAddr" control={control} error={errors.mailSenderAddr} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="Confirm Account Host" name="confirmAccountHost" control={control} error={errors.confirmAccountHost} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="Apple ClientId IOS" name="appleClientIdIOS" control={control} error={errors.appleClientIdIOS} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="Apple ClientId Web" name="appleClientIdWeb" control={control} error={errors.appleClientIdWeb} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <TextField label="Transcode Output Path" name="transcodeOutputPath" control={control} error={errors.transcodeOutputPath} />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col md={4} className="offset-md-4">
                      <Autocomplete
                        options={StorageType}
                        label="Storage Type"
                        name="storageType"
                        control={control}
                        error={errors.storageType}
                        // onHandleChange={handleChangeCategory}
                      />
                    </Col>
                  </Row>
                </TabPane>
                </>
              )
            }
          </Tabs>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button color="error" className="mr-2 d-inline-block" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default TenantForm
