import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip as TooltipAntd } from 'antd'
import 'antd/lib/tooltip/style/index.css'

const Tooltip = (props) => {
  const { className, title, children } = props

  return (
    <TooltipAntd className={`${className === '' ? '' : (' ' + className)}`} placement="topLeft" title={title}>
      {children}
    </TooltipAntd>
  )
}

Tooltip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Tooltip.defaultProps = {
  className: '',
  title: ''
}

export default Tooltip
