import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { getData, formatFormData, formatSlug } from 'common/utils'
import { Row, Col } from 'components/ui'
import TextField from 'components/form/TextField'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { toast } from 'react-toastify'

const ConfigForm = (props) => {
  const history = useHistory()
  const { id, tenantId } = useParams()
  const { handleSubmit, control, setValue, reset, formState: { errors }, setError } = useForm()

  const { data: dataInfo } = useQuery(['getInfoConfig', id], async () => {
    if (id) {
      return await getData('GET', `config/${id}/`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `config/${id}/`, dataForm)
      } else {
        return await getData('POST', 'config/', dataForm)
      }
    }
  })

  useEffect(() => {
    if (dataInfo) {
      const dataInfoResult = dataInfo.data
      reset(dataInfoResult)
    }
  }, [dataInfo])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        await mutation.mutateAsync({
          title: formatFormData(data.title),
          value: formatFormData(data.value)
        })
      } else {
        await mutation.mutateAsync({
          title: formatFormData(data.title),
          slug: formatFormData(data.slug),
          value: formatFormData(data.value),
          tenant: formatFormData(tenantId)
        })
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleChangeName = (e) => {
    if (!id) {
      if (e && e.target) {
        setValue('slug', formatSlug(e.target.value))
      }
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="tenant-form">
      <Card>
        <CardContent>
          <Row>
            <Col md={4} className="offset-md-4">
              <TextField label="Title" name="title" required control={control} error={errors.title} onHandleChange={handleChangeName} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <TextField label="Slug" name="slug" required={!id} disabled={!!id} control={control} error={errors.slug} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <TextField label="Value" name="value" required control={control} error={errors.value} />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button color="error" className="mr-2 d-inline-block" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default ConfigForm
