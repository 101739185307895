import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { getData, formatFormData } from 'common/utils'
import { Row, Col } from 'components/ui'
import TextField from 'components/form/TextField'
import Button from 'components/form/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { toast } from 'react-toastify'
import Autocomplete from 'components/form/Autocomplete'
import Checkbox from 'components/form/Checkbox'

const ProtocolSelect = [
  { value: 'http://', label: 'HTTP' },
  { value: 'https://', label: 'HTTPS' }
]

const TokenType = [
  { value: 'jwt', label: 'JWT' },
  { value: 'vnetwork', label: 'VNETWORK' }
]

const Type = [
  { value: 1, label: 'VOD' },
  { value: 2, label: 'LINEAR CHANNEL' },
  { value: 3, label: 'STATIC' }
]

const ServerForm = (props) => {
  const history = useHistory()
  const { id, tenantId } = useParams()
  const { handleSubmit, control, reset, setValue, formState: { errors }, setError } = useForm()

  // Handle For Case Update
  const [idTenant, setIdTenant] = useState('')
  const [partnerList, setPatnerList] = useState([])

  const { data: dataInfo } = useQuery(['getServerInfo', id], async () => {
    if (id) {
      return await getData('GET', `servers/${id}/`)
    }
  })

  const { data: partners } = useQuery(['getPartnersInfo', idTenant], async () => {
    if (idTenant) {
      return await getData('GET', `tenants/${idTenant}/partners`)
    }
  })

  const mutation = useMutation(async (dataForm) => {
    if (dataForm) {
      if (id) {
        return await getData('PUT', `servers/${id}/`, dataForm)
      } else {
        return await getData('POST', 'servers/', dataForm)
      }
    }
  })

  useEffect(() => {
    if (tenantId) {
      setIdTenant(tenantId)
    }
  }, [tenantId])

  useEffect(() => {
    if (partners) {
      const dataInfoResult = partners?.data?.results || []
      const partnerArr = dataInfoResult.map((item) => {
        return { value: item?.partner_id, label: item?.name }
      })
      partnerArr.push({ value: '', label: 'None' })
      setPatnerList(partnerArr)
    }
  }, [partners])

  useEffect(() => {
    if (dataInfo) {
      const dataInfoResult = dataInfo.data
      reset(dataInfoResult)
      const protocalFile = ProtocolSelect.find(item => item.value === (dataInfoResult?.protocol || 'https://'))
      setValue('protocal', protocalFile)
      const tokenTypeFind = TokenType.find(item => item.value === (dataInfoResult?.token_type || 'jwt'))
      setValue('token_type', tokenTypeFind)
      const typeFind = Type.find(item => item.value === (dataInfoResult?.type || 1))
      setValue('type', typeFind)
      const partnerFind = partnerList.find(item => item.value === (dataInfoResult?.partner_id || ''))
      setValue('partner', partnerFind)
      setIdTenant(dataInfoResult?.tenant_id || '')
    }
  }, [dataInfo, partnerList])

  /* ----------------------------------------------- */
  /* --------------- Handle Event ------------------ */
  /* ----------------------------------------------- */
  const onSubmit = async (data) => {
    try {
      if (id) {
        await mutation.mutateAsync({
          protocol: formatFormData(data?.protocol?.value || 'https://'),
          full_domain: formatFormData(data.full_domain),
          has_token: formatFormData(data.has_token),
          is_active: formatFormData(data.is_active),
          secret_token: formatFormData(data.secret_token),
          type: formatFormData(data?.type?.value || 1),
          token_type: formatFormData(data?.token_type?.value || 'jwt'),
          partner: formatFormData(data?.partner?.value || '')
        })
      } else {
        await mutation.mutateAsync({
          protocol: formatFormData(data?.protocol?.value || 'https://'),
          full_domain: formatFormData(data.full_domain),
          has_token: formatFormData(data.has_token),
          is_active: formatFormData(data.is_active),
          secret_token: formatFormData(data.secret_token),
          type: formatFormData(data?.type?.value || 1),
          token_type: formatFormData(data?.token_type?.value || 'jwt'),
          tenant: formatFormData(tenantId),
          partner: formatFormData(data?.partner?.value || '')
        })
      }
      history.goBack()
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errData = err.response.data && err.response.data.errors
        if (typeof errData === 'object') {
          for (const item in errData) {
            const errItemArr = errData[item]
            setError(item, {
              type: 'manual',
              message: Array.isArray(errItemArr) && errItemArr[0]
            })
          }
        }
      } else {
        toast.error('Something went wrong!')
      }
      console.log(err)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="tenant-form">
      <Card>
        <CardContent>
          <Row>
            <Col md={4} className="offset-md-4">
              <Autocomplete
                options={ProtocolSelect}
                label="Protocal"
                name="protocal"
                control={control}
                error={errors.protocol}
                required
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <TextField label="Domain" name="full_domain" required control={control} error={errors.full_domain}/>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <TextField label="Secret Token" name="secret_token" control={control} error={errors.secret_token} />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <Autocomplete
                options={partnerList}
                label="Partner"
                name="partner"
                control={control}
                error={errors.partner}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <Autocomplete
                required
                options={TokenType}
                label="Token Type"
                name="token_type"
                control={control}
                error={errors.token_type}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <Autocomplete
                required
                options={Type}
                label="Type"
                name="type"
                control={control}
                error={errors.type}
              />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col md={4} className="offset-md-4">
              <Checkbox name="has_token" className="d-inline-block" control={control} label="Has Token" />
              <Checkbox name="is_active" className="d-inline-block" control={control} label="Active" />
            </Col>
          </Row>
        </CardContent>
        <CardActions className="card-action-mui">
          <Button color="error" className="mr-2 d-inline-block" onClick={handleCancel}>Cancel</Button>
          <Button className="mr-2 d-inline-block" onClick={handleSubmit(onSubmit)}>Save</Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default ServerForm
