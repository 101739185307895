import React from 'react'
import Layout from 'features/layout/components/Layout'
import Tenant from '../components/Tenant'

const TenantPage = (props) => {
  return (
    <Layout>
      <Tenant />
    </Layout>
  )
}

export default TenantPage
