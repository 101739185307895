import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import '../styles/left-sidebar.scss'

const Sidebar = (props) => {
  const { collapse } = props

  const location = useLocation()

  const [locationParam, setLocationParam] = useState(null)

  const data = [
    {
      title: 'DASHBOARD',
      items: [
        { title: 'Home', icon: 'fa fa-home', url: '/' }
      ]
    },
    {
      title: 'TENANT MANAGEMENT',
      items: [
        { title: 'Tenant', icon: 'fa fa-archive', url: '/tenant' }
      ]
    }
  ]

  useEffect(() => {
    if (location) {
      // eslint-disable-next-line no-useless-escape
      setLocationParam(location.pathname.replace(/^\/([^\/]*).*$/, '$1'))
    }
  }, [location])

  return (
    <div className="left_sidebar">
      <nav className="sidebar">
        {data.map((item, index) => {
          return (
            <ul key={index} id="main-menu" className="metismenu">
              <li className="g_heading">{item.title}</li>
              {item.items.map((itemChild, indexChild) => {
                return (
                  <li key={indexChild} className={itemChild.url === `/${locationParam}` ? 'active' : ''}>
                    {collapse
                      ? <Tooltip title={itemChild.title} placement="right">
                          <Link to={itemChild.url}>
                            <i className={itemChild.icon}></i><span>{itemChild.title}</span>
                          </Link>
                        </Tooltip>
                      : <Link to={itemChild.url}>
                          <i className={itemChild.icon}></i><span>{itemChild.title}</span>
                        </Link>
                    }
                  </li>
                )
              })}
            </ul>
          )
        })}
      </nav>
    </div>
  )
}

Sidebar.propTypes = {
  collapse: PropTypes.any
}

Sidebar.defaultProps = {
}

export default Sidebar
