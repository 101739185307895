import React from 'react'
import Layout from 'features/layout/components/Layout'
import ServerForm from '../components/ServerForm'

const ServerFormPage = (props) => {
  return (
    <Layout>
      <ServerForm />
    </Layout>
  )
}

export default ServerFormPage
