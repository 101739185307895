import React from 'react'
import { Controller } from 'react-hook-form'
import CheckboxMui from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import PropTypes from 'prop-types'

const Checkbox = (props) => {
  const { className, label, name, control, defaultChecked, disabled } = props

  return (
    <div className={`${className === '' ? '' : (' ' + className)}`}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={<CheckboxMui
                  checked={typeof value === 'undefined' ? defaultChecked : value}
                  disabled={disabled}
                  onChange={onChange}
                />}
                label={label}
                labelPlacement="start"
              />
            </FormGroup>
          )
        }}
      />
    </div>
  )
}

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.any,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool
  // error: PropTypes.any
}

Checkbox.defaultProps = {
  className: '',
  label: '',
  name: '',
  defaultChecked: false,
  disabled: false
}

export default Checkbox
