import React from 'react'
import PropTypes from 'prop-types'

const Alert = (props) => {
  const { type, message, onClose } = props

  return (
    <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
      {message}
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}

Alert.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func
}

Alert.defaultProps = {
  type: '',
  message: '',
  onClose: () => {}
}

export default Alert
