import React from 'react'
import { Controller } from 'react-hook-form'
import TextFieldMui from '@mui/material/TextField'
import PropTypes from 'prop-types'

const TextField = (props) => {
  const { className, type, label, name, variant, size, fullWidth, required, disabled, control, error, onKeyDown, onHandleChange } = props

  return (
    <div className={`${className === '' ? '' : (' ' + className)}`}>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => {
          return (
            <TextFieldMui
              type={type}
              label={label}
              value={value || ''}
              variant={variant}
              size={size}
              multiline={type === 'textarea'}
              rows={type === 'textarea' ? 4 : 0}
              fullWidth={fullWidth}
              required={required}
              disabled={disabled}
              onChange={e => {
                onChange(e)

                if (onHandleChange && typeof onHandleChange === 'function') {
                  onHandleChange(e)
                }
              }}
              onKeyDown={onKeyDown}
              error={!!error}
              helperText={error
                ? error.type === 'required'
                  ? 'This field is required'
                  : error.message
                : null
              }
            />
          )
        }}
      />
    </div>
  )
}

TextField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  control: PropTypes.any,
  error: PropTypes.any,
  onKeyDown: PropTypes.func,
  onHandleChange: PropTypes.func
}

TextField.defaultProps = {
  className: '',
  type: 'text',
  label: '',
  name: '',
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
  required: false,
  disabled: false
}

export default TextField
