import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarCollapse: false,
  errorHtml: null
}

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    toggleSidebarCollapse (state) {
      state.sidebarCollapse = !state.sidebarCollapse
    },
    setSidebarCollapse (state, action) {
      state.sidebarCollapse = action.payload
    },
    setErrorHtml (state, action) {
      state.errorHtml = action.payload
    }
  }
})

export const { toggleSidebarCollapse, setSidebarCollapse, setErrorHtml } = rootSlice.actions
export default rootSlice.reducer
