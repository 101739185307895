import React from 'react'
// import Footer from 'features/footer/components/Footer'
import Login from '../components/Login'

const LoginPage = (props) => {
  return (
    <>
      <Login />
      {/* <Footer /> */}
    </>
  )
}

export default LoginPage
