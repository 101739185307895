import React from 'react'
import PropTypes from 'prop-types'
import ButtonMui from '@mui/material/Button'

const Button = (props) => {
  const { className, variant, color, fullWidth, startIcon, onClick, children } = props

  return (
    <div className={`${className === '' ? '' : (' ' + className)}`}>
      <ButtonMui variant={variant} color={color} onClick={onClick} fullWidth={fullWidth} startIcon={startIcon}>
        {children}
      </ButtonMui>
    </div>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  startIcon: PropTypes.any,
  onClick: PropTypes.func,
  children: PropTypes.any
}

Button.defaultProps = {
  className: '',
  variant: 'contained',
  color: 'primary',
  fullWidth: false
}

export default Button
