import React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import PropTypes from 'prop-types'
import './styles/autocomplete-group.scss'

const Autocomplete = (props) => {
  const { label, name, data, control, error, required, isDisabled, isSearchable } = props

  return (
    <div className="autocomplete-group">
      <label htmlFor={name} className="form-label mb-1">{label} {required && <span className="text-danger">*</span>}</label>
      <Controller
        name={name}
        control={control}
        // defaultValue={''}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              className="autocomplete-group-select"
              classNamePrefix={error ? 'admon_autocomplete' : ''}
              options={data}
              value={value}
              onChange={onChange}
              isSearchable={isSearchable}
              isDisabled={isDisabled}
            />
          )
        }}
      />

      {error
        ? error.type === 'required'
          ? <div className="text-danger pt-1">
            {label} required
          </div>
          : <div className="text-danger pt-1">
            {error.message}
          </div>
        : <div className="text-danger pt-1"></div>
      }
    </div>
  )
}

Autocomplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.array,
  isSearchable: PropTypes.bool,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  control: PropTypes.any,
  error: PropTypes.any
}

Autocomplete.defaultProps = {
  label: '',
  name: '',
  data: [],
  isSearchable: true,
  isDisabled: false,
  required: false
}

export default Autocomplete
