import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Switch } from 'antd'

const SwitchGroup = (props) => {
  const { className, label, name, control, disabled } = props

  return (
    <div className={`${className === '' ? '' : (' ' + className)}`}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Switch
              checkedChildren={label}
              unCheckedChildren={label}
              defaultChecked={true}
              checked={value}
              onChange={onChange}
              disabled={disabled}
            />
          )
        }}
      />
    </div>
  )
}

SwitchGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  control: PropTypes.any,
  error: PropTypes.any
}

SwitchGroup.defaultProps = {
  className: '',
  label: '',
  name: '',
  disabled: false
}

export default SwitchGroup
