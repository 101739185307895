import React from 'react'
// import Layout from 'features/layout/components/Layout'
import Error from '../components/Error'

const ErrorPage = (props) => {
  return (
    // <Layout>
    <Error />
    // </Layout>
  )
}

export default ErrorPage
