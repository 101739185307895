import React from 'react'
import PropTypes from 'prop-types'

const Button = (props) => {
  const { className, color, children, onClick } = props

  return (
    <button type="button" className={`btn btn-${color}${className === '' ? '' : (' ' + className)}`} onClick={onClick}>
      {children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClick: PropTypes.func
}

Button.defaultProps = {
  className: '',
  color: 'primary',
  onClick: () => {}
}

export default Button
