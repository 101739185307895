import React from 'react'
import '../styles/home.scss'

const Home = (props) => {
  return (
    <div className="home">
    </div>
  )
}

export default Home
