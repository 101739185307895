import React from 'react'
import Layout from 'features/layout/components/Layout'
import Home from '../components/Home'

const HomePage = (props) => {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}

export default HomePage
