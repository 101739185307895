import ErrorPage from './features/error/pages/ErrorPage'
import LoginPage from './features/login/pages/LoginPage'
import HomePage from './features/home/pages/HomePage'
import TenantPage from './features/tenant/pages/TenantPage'
import TenantFormPage from './features/tenant/pages/TenantFormPage'
import ConfigPage from './features/config/pages/ConfigPage'
import ConfigFormPage from './features/config/pages/ConfigFormPage'
import ServersPage from 'features/servers/pages/ServerPage'
import ServerFormPage from 'features/servers/pages/ServerFormPage'

const routes = [
  { path: '/error', private: true, component: ErrorPage, exact: true },
  { path: '/login', private: false, component: LoginPage, exact: true },
  { path: '/', private: true, component: HomePage, exact: true },

  // Tenant
  { path: '/tenant', private: true, component: TenantPage, exact: true },
  { path: '/tenant/add', private: true, component: TenantFormPage, exact: true },
  { path: '/tenant/:id', private: true, component: TenantFormPage, exact: true },

  // Config
  { path: '/tenant/:tenantId/configs', private: true, component: ConfigPage, exact: true },
  { path: '/config/:tenantId/add', private: true, component: ConfigFormPage, exact: true },
  { path: '/config/:id', private: true, component: ConfigFormPage, exact: true },

  // Server
  { path: '/tenant/:tenantId/servers', private: true, component: ServersPage, exact: true },
  { path: '/servers/:tenantId/add', private: true, component: ServerFormPage, exact: true },
  { path: '/servers/:id', private: true, component: ServerFormPage, exact: true }
]

export default routes
