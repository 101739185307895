import React from 'react'
import Layout from 'features/layout/components/Layout'
import Servers from '../components/Servers'

const ServersPage = (props) => {
  return (
    <Layout>
      <Servers />
    </Layout>
  )
}

export default ServersPage
