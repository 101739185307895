import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { getData } from 'common/utils'
import Switch from '@mui/material/Switch'
import CoreList from 'features/core/components/CoreList'

const Config = (props) => {
  const queryClient = useQueryClient()
  const { tenantId } = useParams()

  const mutation = useMutation(async ({ id, dataForm }) => {
    if (id && dataForm) {
      return await getData('PUT', `config/${id}/active/`, dataForm)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getConfigList')
    }
  })

  const handleChangeStatus = async (e, id) => {
    try {
      await mutation.mutateAsync({
        id: id,
        dataForm: {
          is_active: e.target.checked
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
      ellipsis: true
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      width: '10%',
      ellipsis: true
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: '10%',
      ellipsis: true
    },
    {
      title: 'Active',
      key: 'active',
      align: 'is_active',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <Switch checked={record.is_active} onChange={e => handleChangeStatus(e, record.id)} />
      },
      sorter: (a, b) => {
        return b.is_active - a.is_active
      }
    }
  ]

  return (
    <div className="config">
      <CoreList
        code="Config"
        endpoint="config"
        url="config"
        urlAdd={`/config/${tenantId}`}
        params={`&tenantId=${tenantId}`}
        columns={columns}
      />
    </div>
  )
}

export default Config
