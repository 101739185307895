import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { getData } from 'common/utils'
import Switch from '@mui/material/Switch'
import CoreList from 'features/core/components/CoreList'
import CheckIcon from '@mui/icons-material/Check'

const Servers = (props) => {
  const queryClient = useQueryClient()
  const { tenantId } = useParams()

  const mutation = useMutation(async ({ id }) => {
    if (id) {
      return await getData('PUT', `servers/${id}/update-status/`, {})
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getServersList')
    }
  })

  const handleChangeStatus = async (e, id) => {
    try {
      await mutation.mutateAsync({
        id: id
      })
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'Full Domain',
      dataIndex: 'full_domain',
      key: 'full_domain',
      width: '20%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div key={index}>{`${record?.protocol}${record?.full_domain}`}</div>
        )
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (<div key={index}>{
          parseInt(record?.type || 1) === 1 ? 'VOD' : (record?.type === 2) ? 'LINEAR CHANNEL' : 'STATIC'
        }</div>)
      }
    },
    {
      title: 'Is Partner',
      dataIndex: 'partner_id',
      key: 'partner_id',
      width: '10%',
      ellipsis: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        if (record && record?.partner_id) {
          return <CheckIcon color="success" />
        } else {
          return null
        }
      }
    },
    {
      title: 'Token Type',
      dataIndex: 'token_type',
      key: 'token_type',
      width: '10%',
      ellipsis: true
    },
    {
      title: 'Has Token',
      key: 'has_token',
      align: 'has_token',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <Switch disabled checked={record.has_token}/>
      }
    },
    {
      title: 'Active',
      key: 'active',
      align: 'is_active',
      width: '10%',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return <Switch checked={record.is_active} onChange={e => handleChangeStatus(e, record.id)} />
      },
      sorter: (a, b) => {
        return b.is_active - a.is_active
      }
    }
  ]

  return (
    <div className="server">
      <CoreList
        code="Servers"
        endpoint={`tenants/${tenantId}/servers`}
        url="servers"
        urlAdd={`/servers/${tenantId}`}
        columns={columns}
      />
    </div>
  )
}

export default Servers
